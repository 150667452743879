@import './const.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Arial', sans-serif;

  --BACKGROUND_1: #ffffff;
  --BACKGROUND_2: #f6faf9;
  --BACKGROUND_3: #e4f0ef;
  --BACKGROUND_SVG: #f2f8f6;
  --TEXT: #000000;
  --TEXT_INVERT: #ffffff;
  --TEXT_GRAY: #8b8b8b;
  --TEXT_WHITE: #ffffff;
  --BORDER: #daece7;
  --BLUE: #0d9486;
  --TEXT_LINK: #0373a7;

  @media screen and (prefers-color-scheme: dark) {
    --BACKGROUND_1: #020a09;
    --BACKGROUND_2: #041412;
    --BACKGROUND_3: #07302b;
    --BACKGROUND_SVG: #051c1a;
    --TEXT: #ffffff;
    --TEXT_INVERT: #000000;
    --TEXT_GRAY: #8b8b8b;
    --BORDER: #092e29;
    --BLUE: #0d9486;
    --TEXT_LINK: #69c5d6;
  }

  background-color: $BACKGROUND_1;
  transition: background-color $default-timing-duration $default-timing-function;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  margin: 0;
  outline: none;

  &::-moz-focus-inner {
    border: 0;
  }
}
